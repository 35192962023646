import React from 'react'
import LearnMoreButton from '../components/LearnMoreButton'
import 'bulma/css/bulma.min.css'

const LandingPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="hero is-fullheight"
        style={{
          backgroundImage: `url(/assets/images/virtual-classroom-study-space.webp)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        ></div>
        <div className="hero-body" style={{position: 'relative', zIndex: 2}}>
          <div className="container has-text-centered">
            <h1
              className="title is-1 has-text-white"
              style={{marginBottom: '1rem'}}
            >
              Inscríbete en nuestras clases de conversación
            </h1>
            <h2
              className="subtitle is-3 has-text-white"
              style={{marginBottom: '2rem'}}
            >
              Mejora tu fluidez con sesiones guiadas por hablantes nativos de
              francés
            </h2>
            <LearnMoreButton />
          </div>
        </div>
      </section>

      {/* Subscription Form */}
      <section className="section" id="form-section">
        <div
          className="container"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <iframe
            width="540"
            height="750"
            src="https://e7b4fa4e.sibforms.com/serve/MUIFAFANoP229GSDd8KLKfB6il09qoa3vgCs-VmKHzINvj453mVuug2cNozQuT01N7qHX31mf2pRWMX4CW8AByqNfhobjBE1AIVy99SFKfGaeqqWjh0zsnzzcS6k-54s2xZRIj1BZfyJn9inohfIjtC1XPO4BsMclfL26dN5F0Fr0QCJ_Qh1onz2yrXs5oMmD-JrPrsAGaLU58Jf"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
            style={{
              overflow: 'hidden',
              maxWidth: '100%',
              border: 'none',
              margin: '0 auto',
            }}
          ></iframe>
        </div>
      </section>
    </div>
  )
}

export default LandingPage
