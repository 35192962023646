import React from 'react'

export default function LearnMoreButton() {
  const handleLearnMoreClick = () => {
    const formSection = document.getElementById('form-section')
    if (formSection) {
      formSection.scrollIntoView({behavior: 'smooth'})
    }
  }

  return (
    <button
      className="button is-large is-warning"
      onClick={handleLearnMoreClick}
      style={{marginBottom: '2rem'}}
    >
      Más Información
    </button>
  )
}
